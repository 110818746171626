import React from "react";

import styles from "./backgroundRoute.module.css";

const BackgroundRoute2 = () => {
  return (
    <svg
      width="506"
      height="330"
      viewBox="0 0 506 330"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          id="route6"
          d="M81.6729 274.098L8.17285 252.098L74.1729 216.598L131.673 113.598L335.173 4.09839L421.673 29.5984L387.673 110.598L471.173 136.098C471.173 136.098 508.283 148.38 501.673 169.689C495.053 190.998 453.673 209.598 453.673 209.598L377.673 187.098L267.173 246.598L345.673 269.598L240.95 326.295L163.673 302.598L237.173 262.598L150.673 236.598L81.6729 274.098Z"
          pathLength="1618.8"
        />

        <radialGradient
          id="fadeCar3"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" stopOpacity="1" offset="0" />
          <stop stopColor="#5BBA6F" stopOpacity="0.5" offset="0.5" />
          <stop stopColor="#5BBA6F" stopOpacity="0" offset="1" />
        </radialGradient>

        <radialGradient
          id="fadeTrail3"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="200"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.7" offset="0" />
          <stop stopColor="#73E2A7" stopOpacity="0.4" offset="0.8" />
          <stop stopColor="#73E2A7" stopOpacity="0.1" offset="1" />
        </radialGradient>

        <mask id="car6" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute6} xlinkHref="#route6">
            <animate
              attributeName="stroke-dashoffset"
              from="100"
              to="-1518.8"
              dur="22s"
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="trail6" maskUnits="userSpaceOnUse">
          <use className={styles.maskTrailRoute6} xlinkHref="#route6">
            <animate
              attributeName="stroke-dashoffset"
              from="338"
              to="-1280.8"
              dur="22s"
              repeatCount="indefinite"
            />
          </use>
        </mask>
      </defs>

      <g style={{ mask: "url(#car6)" }}>
        <circle style={{ fill: "url(#fadeCar3)" }} cx="0" cy="0" r="100">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur="22s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route6" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#trail6)" }}>
        <circle style={{ fill: "url(#fadeTrail3)" }} cx="0" cy="0" r="200">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur="22s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route6" />
          </animateMotion>
        </circle>
      </g>
    </svg>
  );
};

export default BackgroundRoute2;
