import React from "react";

import styles from "./backgroundRoute.module.css";

const BackgroundRoute3 = () => {
  return (
    <svg
      width="598"
      height="246"
      viewBox="0 0 598 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          id="route7"
          d="M171.243 241.915L7.92285 193.379L175.423 103.379L312.423 96.8794L231.923 72.8794L360.423 3.37939L433.923 24.8794C433.923 24.8794 473.243 5.30341 512.183 3.60941C551.133 1.91641 590.423 15.8794 590.423 15.8794L395.423 121.05L470.923 143.379L361.923 202.379L286.423 179.879L171.243 241.915Z"
          pathLength="1599.4"
        />

        <radialGradient
          id="fadeCar3"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" stopOpacity="1" offset="0" />
          <stop stopColor="#5BBA6F" stopOpacity="0.5" offset="0.5" />
          <stop stopColor="#5BBA6F" stopOpacity="0" offset="1" />
        </radialGradient>

        <radialGradient
          id="fadeTrail3"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="200"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.7" offset="0" />
          <stop stopColor="#73E2A7" stopOpacity="0.4" offset="0.8" />
          <stop stopColor="#73E2A7" stopOpacity="0.1" offset="1" />
        </radialGradient>

        <mask id="car7" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute7} xlinkHref="#route7">
            <animate
              attributeName="stroke-dashoffset"
              from="96"
              to="-1503.4"
              dur="22s"
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="trail7" maskUnits="userSpaceOnUse">
          <use className={styles.maskTrailRoute7} xlinkHref="#route7">
            <animate
              attributeName="stroke-dashoffset"
              from="335"
              to="-1264.4"
              dur="22s"
              repeatCount="indefinite"
            />
          </use>
        </mask>
      </defs>

      <g style={{ mask: "url(#car7)" }}>
        <circle style={{ fill: "url(#fadeCar3)" }} cx="0" cy="0" r="100">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur="22s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route7" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#trail7)" }}>
        <circle style={{ fill: "url(#fadeTrail3)" }} cx="0" cy="0" r="200">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur="22s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route7" />
          </animateMotion>
        </circle>
      </g>
    </svg>
  );
};

export default BackgroundRoute3;
