import React from "react";
import PropTypes from "prop-types";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import Typography from "root/components/Typography";
import Section from "root/components/Section";
import Footer from "root/sections/Footer";
import Button from "root/components/Button";
import BackgroundRoute2 from "root/components/SvgImages/BackgroundRoute2";
import BackgroundRoute3 from "root/components/SvgImages/BackgroundRoute3";

import mdxStyles from "root/styles/mdx.module.css";
import styles from "./index.module.css";

function Position({ pageContext }) {
  const { frontmatter, body } = pageContext.position;

  return (
    <div>
      <SEO title="Drivit" />
      <Layout>
        <Header currentPage="position" />
        <Section>
          <div className={styles.title}>
            <Typography variant="h1" color="green" weight="bold">
              {frontmatter.position}
            </Typography>
          </div>
          <div className={styles.backgroundSection}>
            <div className={styles.backgroundShape1}>
              <BackgroundRoute2 />
            </div>
            <div className={styles.backgroundShape2}>
              <BackgroundRoute3 />
            </div>
          </div>
          <div className={styles.root}>
            <div className={mdxStyles.content}>
              <MDXRenderer>{body}</MDXRenderer>
            </div>
            <div className={styles.button}>
              <a href="mailto:jobs@drivit.com">
                <Button size="full" color="green">
                  <Typography color="white" weight="bold">
                    Apply Now
                  </Typography>
                </Button>
              </a>
            </div>
          </div>
        </Section>
        <Footer backgroundColor="lightGrey" />
      </Layout>
    </div>
  );
}

Position.propTypes = {
  pageContext: PropTypes.shape({
    position: PropTypes.shape({
      frontmatter: PropTypes.shape({
        position: PropTypes.string.isRequired,
      }).isRequired,
      body: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Position;
